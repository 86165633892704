<template>
  <div class="load-more-wrap mt-4 text-center">
    <v-btn
      text
      plain
      :color="$vuetify.theme.dark ? '#fff' : '#222'"
      :ripple="false"
      :opacity="1"
      @click="loadMore"
      >{{ this.$t("activities.table.loadMore")
      }}<ph-arrow-counter-clockwise
        class="ml-3"
        :size="16"
        :color="$vuetify.theme.dark ? '#fff' : '#222'"
    /></v-btn>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PhArrowCounterClockwise } from "phosphor-vue";
export default {
  components: {
    PhArrowCounterClockwise,
  },
  props: {
    direction: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      activityCount: "wallet/activityCount",
      activeFilter: "wallet/activeFilter",
      currentDateFilter: "wallet/currentDateFilter",
    }),
  },
  methods: {
    loadMore() {
      let dateFrom = new Date();

      if (isNaN(this.currentDateFilter)) {
        dateFrom = "";
      } else {
        dateFrom.setDate(dateFrom.getDate() - this.currentDateFilter);
      }

      this.$store.dispatch("wallet/getUserActivity", {
        filter: this.activeFilter,
        direction: this.direction,
        skip: 0,
        limit: this.activityCount,
        dateFrom: dateFrom.toLocaleString("en-US"),
        dateTo: new Date().toLocaleString("en-US"),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)::v-deep
  .v-btn__content {
  opacity: 1;
}
</style>
